import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { init, BrowserTracing, Replay } from '@sentry/react';
import { getClaim } from './utils/auth/getClaim';
import { getCompanyName } from './features/navigation/utils/getCompanyName';

const email = getClaim('email') || 'unknown';
const id = getClaim('sub') || 'unknown';
const segment = getCompanyName(email) || 'unknown';
init({
  environment: process.env.NODE_ENV,
  debug: process.env.NODE_ENV === 'development' ? true : false,
  release: process.env.REACT_APP_RELEASE_VERSION,
  dsn: 'https://50d546eaa0eb4dc7a0c37615f7a96180@o4504101583454208.ingest.sentry.io/4505042358697984',
  integrations: [
    new BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['app.relay.bid'],
    }),
    new Replay(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  initialScope: { user: { email, id, segment } }, // ! could be improved with callback api & amplify.getAuthenticatedUser
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
});
const container = document.getElementById('root')!;
const root = createRoot(container);
Amplify.configure(awsExports);

root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <BrowserRouter>
        <ReduxProvider store={store}>
          <App />
        </ReduxProvider>
      </BrowserRouter>
    </Authenticator.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
