/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../app/services/types.generated';

import { api } from '../../../app/services/hasuraBaseApi';
export type GetAllAccountUsersByEmailQueryVariables = Types.Exact<{
  _eq?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetAllAccountUsersByEmailQuery = { __typename?: 'query_root', account: Array<{ __typename?: 'account', users: Array<{ __typename?: 'user', name: string, email: string, user_role: { __typename?: 'user_role', value: string } }> }>, user_role: Array<{ __typename?: 'user_role', value: string }> };


export const GetAllAccountUsersByEmailDocument = `
    query GetAllAccountUsersByEmail($_eq: String) {
  account(where: {users: {email: {_eq: $_eq}}}) {
    users(where: {email: {_neq: $_eq}}) {
      user_role {
        value
      }
      name
      email
    }
  }
  user_role {
    value
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAllAccountUsersByEmail: build.query<GetAllAccountUsersByEmailQuery, GetAllAccountUsersByEmailQueryVariables | void>({
      query: (variables) => ({ document: GetAllAccountUsersByEmailDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetAllAccountUsersByEmailQuery, useLazyGetAllAccountUsersByEmailQuery } = injectedRtkApi;

