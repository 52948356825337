/**
 * ************************************
 *
 * @module  exchangeSlice.ts
 * @author Adam Joesten
 * @date 9/20/2022
 * @description A slice of redux reducer logic, actions, and state for the accounts feature
 *
 * ************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * ====================================
 *               TYPES
 * ====================================
 */

export interface AccountsState {
  currentTabName: string;
}

/**
 * ====================================
 *           INITIAL STATE
 * ====================================
 */

const initialState: AccountsState = {
  currentTabName: 'Your Account',
};

/**
 * ====================================
 *               SLICE
 * ====================================
 */

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    switchCurrentTabName: (state, action: PayloadAction<string>) => {
      state.currentTabName = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

/**
 * ====================================
 *              EXPORTS
 * ====================================
 */

export default accountsSlice.reducer;
export const { switchCurrentTabName } = accountsSlice.actions;
