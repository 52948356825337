/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 *
 * npm run codegen
 *
 * for this file to be re-created
 */

import * as Types from '../../../app/services/types.generated';

import { api } from '../../../app/services/hasuraBaseApi';
export type GetOrgIdByAccountIdQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['uuid']>;
}>;


export type GetOrgIdByAccountIdQuery = { __typename?: 'query_root', account: Array<{ __typename?: 'account', id: any, name: string, organization_id: string }> };


export const GetOrgIdByAccountIdDocument = `
    query GetOrgIdByAccountId($id: uuid) {
  account(where: {id: {_eq: $id}}) {
    id
    name
    organization_id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetOrgIdByAccountId: build.query<GetOrgIdByAccountIdQuery, GetOrgIdByAccountIdQueryVariables | void>({
      query: (variables) => ({ document: GetOrgIdByAccountIdDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrgIdByAccountIdQuery, useLazyGetOrgIdByAccountIdQuery } = injectedRtkApi;

