/**
 * ************************************
 *
 * @module hooks.ts
 * @author Adam Joesten
 * @date 6/15/22
 * @description A place to create typed and custom hooks
 *
 * ************************************
 */

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
