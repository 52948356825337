import { Routes, Route } from 'react-router-dom';
import SplitPane from './components/SplitPlane';
import Accounts from './features/accounts/Accounts';
import Navigation from './features/navigation/Navigation';
import { Reporting } from './features/reporting/NewReporting';
import { Filtering } from './features/filtering/Filtering';
import { DealCuration } from './features/deals/DealCuration';
import { Supply } from './features/retool_supply';
import { Demand } from './features/retool_demand';
import { SupplyApproval } from './features/retool_supply_approval';

export const AppRouter = () => {
  return (
    <Routes>
      <Route
        index
        element={<SplitPane left={<Navigation />} right={<Reporting />} />}
      />
      <Route
        path="filtering"
        element={<SplitPane left={<Navigation />} right={<Filtering />} />}
      />
      <Route
        path="reporting"
        element={<SplitPane left={<Navigation />} right={<Reporting />} />}
      />
      <Route
        path="deals"
        element={<SplitPane left={<Navigation />} right={<DealCuration />} />}
      />
      <Route
        path="supply"
        element={<SplitPane left={<Navigation />} right={<Supply />} />}
      />
      <Route
          path="supply_approval"
          element={<SplitPane left={<Navigation />} right={<SupplyApproval />} />}
      />
      <Route
        path="demand"
        element={<SplitPane left={<Navigation />} right={<Demand />} />}
      />
      <Route
        path="accounts"
        element={<SplitPane left={<Navigation />} right={<Accounts />} />}
      />
    </Routes>
  );
};
