/**
 * ************************************
 *
 * @module Login.tsx
 * @author Adam Joesten
 * @date 7/5/22
 * @description A place for users to login via AWS Amplify
 *
 * ************************************
 */

import { Amplify } from 'aws-amplify';
import {
  Authenticator,
  Button,
  Heading,
  useAuthenticator,
  useTheme,
  View,
  Text,
  ThemeProvider,
  Theme,
} from '@aws-amplify/ui-react';
import awsExports from '../../aws-exports';
import { ReactComponent as RelayLogoVertical } from '../../assets/RelayLogoVertical.svg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

Amplify.configure(awsExports);

const theme: Theme = {
  name: 'custom-theme',
  tokens: {
    colors: {},
    fonts: {
      default: {
        variable: { value: 'Inter, sans-serif' },
        static: { value: 'Inter, sans-serif' },
      },
    },
    components: {
      button: {
        primary: {
          backgroundColor: '',
        },
      },
      //   card: {
      //     backgroundColor: { value: '{colors.background.secondary}' },
      //     outlined: {
      //       borderColor: { value: '{colors.black}' },
      //     },
      //   },
      //   heading: {
      //     color: { value: '{colors.brand.secondary[80]}' },
      //   },
      //   text: {
      //     color: { value: '{colors.brand.primary[80]}' },
      //   },
    },
  },
};

const components = {
  Header() {
    return (
      <View className="flex justify-center">
        <RelayLogoVertical className="w-96" />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  SignUp: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Create a new account
        </Heading>
      );
    },
    Footer() {
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toSignIn}
            size="small"
            variation="link"
          >
            Back to Sign In
          </Button>
        </View>
      );
    },
  },
  ConfirmSignUp: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
  SetupTOTP: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    // Footer() {
    //   return <Text>Footer Information</Text>;
    // },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Enter your email',
    },
  },
  signUp: {
    password: {
      label: 'Password:',
      placeholder: 'Enter your Password:',
      isRequired: false,
      order: 2,
    },
    confirm_password: {
      label: 'Confirm Password:',
      order: 1,
    },
  },
  forceNewPassword: {
    password: {
      placeholder: 'Enter your Password:',
    },
  },
  resetPassword: {
    username: {
      placeholder: 'Enter your email:',
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: 'Enter your Confirmation Code:',
      label: 'Confirmation Code',
      isRequired: false,
    },
    confirm_password: {
      placeholder: 'Enter your Password Please:',
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: 'test issuer',
      totpUsername: 'amplify_qr_test_user',
    },
    confirmation_code: {
      label: 'Confirmation Code',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'Confirmation Code',
      placeholder: 'Enter your Confirmation Code:',
      isRequired: false,
    },
  },
};

export const Login = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const navigate = useNavigate();
  let from = '/relay';
  useEffect(() => {
    if (route === 'authenticated') navigate(from);
  }, [route, navigate, from]);
  return (
    <ThemeProvider theme={theme}>
      <Authenticator
        className="h-screen w-screen"
        formFields={formFields}
        components={components}
        hideSignUp={true}
        socialProviders={[]} // remove to add Google SSO back in
      ></Authenticator>
    </ThemeProvider>
  );
};
