import { createSlice } from '@reduxjs/toolkit';

interface Supply {
  id: string;
  name?: string;
  enabled?: boolean;
  channels?: string[];
}

const initialState = {};

const supplySlice = createSlice({
  name: 'supply',
  initialState,
  reducers: {},
});

export default supplySlice.reducer;
export const {} = supplySlice.actions;
