import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';
import { getClaim } from '../../utils/auth/getClaim';
import { getIdToken } from '../../utils/auth/getIdToken';
interface HasuraClaims {
  'X-Hasura-Allowed-Roles': string[];
  'X-Hasura-Default-Role': string;
  'X-Hasura-Role': string;
  'X-Hasura-User-Id': string;
}

type Dimension = 'date' | 'supply_id' | 'demand_id' | 'domain' | 'channel_id';
type SubQuery = 'or' | 'and';

type Filter =
  | { [K in Dimension]?: Predicate }
  | { [K in SubQuery]?: Array<Filter> };

export type Metric =
  | 'outbound_bid_requests'
  | 'bid_responses'
  | 'avg_bid_price';

interface In {
  in?: Array<string>;
  gte?: string;
  eq?: string;
  lt?: string;
  gt?: string;
  lte?: string;
}

type Predicate = In;

interface ReportingQueryBody {
  dimensions?: Array<Dimension>;
  filters?: Array<Filter>;
  metrics?: Array<Metric>;
}

export type ReportingResponse = { records: Report[] };
export interface Report {
  date: Date;
  supply_id?: String | null;
  demand_id?: String | null;
  domain: String;
  outbound_bid_requests: number;
  bid_responses: number;
  avg_bid_price: number;
}

interface TempReport {
  date: string;
  supply_id: string;
  demand_id: string;
  domain: string;
  outbound_bid_requests: string;
  bid_responses: string;
  avg_bid_price: string;
}

// {
//     "dimensions": [
//         "date",
//         "supply_id",
//         "demand_id",
//         "domain"
//     ],
//     "filters": [
//         {
//             "domain": {
//                 "in": [
//                     "example.com",
//                     "kevel.com"
//                 ]
//             }
//         }
//     ],
//     "metrics": [
//         "outbound_bid_requests",
//         "bid_responses",
//         "avg_bid_price"
//     ]
// }
const hasuraClaims: HasuraClaims = JSON.parse(
  getClaim('https://hasura.io/jwt/claims') || '{}',
);
const idToken = getIdToken();

export const reducerPath = 'reportingBaseApi';

export type RetoolUrlResponse = { url: String };

export interface RetoolUrlRequest {
  weird_uuid_thing: String;
}

export const api = createApi({
  reducerPath,
  tagTypes: ['Supply'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.relay.bid/',
    prepareHeaders: async (headers, { getState }) => {
      const currentSession = await Auth.currentSession();
      const jwt = currentSession.getIdToken().getJwtToken();
      headers.append('Authorization', `Bearer ${jwt}`);
      return headers;
    },
  }),
  refetchOnFocus: false,
  endpoints: (builder) => ({
    fetchRetoolUrl: builder.query<
      RetoolUrlResponse,
      { a: string; body: RetoolUrlRequest }
    >({
      query: ({ a, body }) => ({
        url: '/m/v1/retool',
        method: 'POST',
        params: { a },
        body,
      }),
    }),
    fetchReport: builder.query<
      ReportingResponse,
      { a: string; body: ReportingQueryBody }
    >({
      transformResponse: (response: { records: TempReport[] }) => {
        const records: Report[] = response.records.map((rec) => {
          return {
            date: new Date(rec.date),
            supply_id: rec.supply_id,
            demand_id: rec.demand_id,
            domain: rec.domain,
            outbound_bid_requests: parseInt(rec.outbound_bid_requests),
            bid_responses: parseInt(rec.bid_responses),
            avg_bid_price: parseFloat(rec.avg_bid_price),
          };
        });

        return { records: records };
      },
      query: ({ a, body }) => ({
        url: '/m/v1/report/',
        method: 'POST',
        params: { a },
        body,
      }),
    }),
  }),
});
