import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import classNames from '../../utils/tailwind/classNames';
import { switchCurrentTabName } from './navigationSlice';
import { TabType } from './NavigationTabList';

interface NavigationTabProps {
  name: TabType['name'];
  href: string;
  count?: number;
  external?: boolean;
  icon: JSX.Element;
}

const NavigationTab = memo(
  ({ name, href, count, external, icon }: NavigationTabProps) => {
    const currentTabName = useAppSelector(
      (state) => state.navigationReducer.currentTabName,
    );
    const dispatch = useAppDispatch();
    return (
      <div
        className={classNames(
          currentTabName === name ? 'border-r-4 border-relay-blue-500' : '',
          'group active:border-none',
        )}
      >
        {external === false || external === undefined ? (
          <Link
            to={href}
            className={classNames(
              currentTabName === name
                ? 'text-relay-blue-500'
                : 'group-hover:bg-relay-grey-200',
              'mr-4 ml-2 flex items-center rounded-md py-2 pl-3 pr-2',
            )}
            onClick={() => dispatch(switchCurrentTabName(name))}
          >
            {icon}
            <span className="ml-4 flex-1 text-2xl">{name}</span>
          </Link>
        ) : (
          <a
            href={href}
            target="_blank"
            className={classNames(
              currentTabName === name
                ? 'text-relay-blue-500'
                : 'group-hover:bg-relay-grey-200',
              'mr-4 ml-2 flex items-center rounded-md py-2 pl-3 pr-2',
            )}
            rel="noreferrer"
          >
            {icon}
            <span className="ml-4 flex-1 text-2xl">{name}</span>
          </a>
        )}
      </div>
    );
  },
);

export default NavigationTab;
