import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
} from '@reduxjs/toolkit/query/react';
import { GraphQLClient } from 'graphql-request';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { getClaim } from '../../utils/auth/getClaim';
import { getIdToken } from '../../utils/auth/getIdToken';
import { Auth } from 'aws-amplify';
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes';
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

interface HasuraClaims {
  'X-Hasura-Allowed-Roles': string[];
  'X-Hasura-Default-Role': string;
  'X-Hasura-Role': string;
  'X-Hasura-User-Id': string;
}
const idToken = getIdToken();

const queryWrapper = () => {
  const hasuraClaims: HasuraClaims = JSON.parse(
    getClaim('https://hasura.io/jwt/claims') || '{}',
  );
  const client = new GraphQLClient('https://graphql.relay.bid/v1/graphql', {
    headers: new Headers({
      Authorization: `Bearer ${idToken}`,
      'X-Hasura-Role': hasuraClaims['X-Hasura-Role'],
      'X-Hasura-User-Id': hasuraClaims['X-Hasura-User-Id'],
    }),
  });
  let baseQuery = graphqlRequestBaseQuery({ client });

  /**
   *
   *
   * RETURN
   *
   */
  return async (
    { document, variables }: { document: string; variables: any },
    api: BaseQueryApi,
    extraOptions: {},
  ) => {
    const session = await Auth.currentSession();

    if (!session?.isValid || idToken === undefined) {
      // try to get a new token
      const hasuraClaims: HasuraClaims = JSON.parse(
        getClaim('https://hasura.io/jwt/claims') || '{}',
      );

      const newClient = new GraphQLClient(
        'https://graphql.relay.bid/v1/graphql',
        {
          headers: new Headers({
            Authorization: `Bearer ${JSON.stringify(
              session.getIdToken().getJwtToken(),
            ).replaceAll('"', '')}`,
            'X-Hasura-Role': hasuraClaims['X-Hasura-Role'],
            'X-Hasura-User-Id': hasuraClaims['X-Hasura-User-Id'],
          }),
        },
      );
      baseQuery = graphqlRequestBaseQuery({ client: newClient });
    }
    let result = await baseQuery({ document, variables }, api, extraOptions);
    return result;
  };
};

const baseQueryWithReauth: BaseQueryFn<
  { document: string; variables: any },
  unknown,
  ErrorResponse
> = queryWrapper();
export const reducerPath = 'hasuraBaseApi';
export const api = createApi({
  reducerPath,
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
