import Retool from 'react-retool';
import { useGetOrgIdByAccountIdQuery } from '../accounts/api/GetOrgIdByAccountId.generated';
import {
  api as reportingApi,
  // RetoolUrlResponse,
  // RetoolUrlRequest,
} from '../../app/services/reportingBaseApi';
import { getClaim } from '../../utils/auth/getClaim';

const RetoolComp = (props) => {
  const component_uuid = props.component_uuid
  const accountId = getClaim('custom:account_id');
  const { data: getOrgData } =
    useGetOrgIdByAccountIdQuery({ id: accountId });
  const orgId = getOrgData?.account?.[0].organization_id || '';
  const data = reportingApi.useFetchRetoolUrlQuery({
    a: orgId,
    body: { component_uuid }
  }, { refetchOnMountOrArgChange: true })
  const baseUrl = data?.data?.url
  if (baseUrl) {
    const url = "https://" + baseUrl;
    return (
      <Retool url={url} />
    );
  } else {
    return;
  }

};

export default RetoolComp;
