/**
 * ************************************
 *
 * @module  exchangeSlice.ts
 * @author Adam Joesten
 * @date 9/20/2022
 * @description A slice of redux reducer logic, actions, and state for the navigation feature
 *
 * ************************************
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TabType } from './NavigationTabList';

/**
 * ====================================
 *               TYPES
 * ====================================
 */

type TabName = TabType['name'];
export interface NavigationState {
  currentTabName: TabName;
}

/**
 * ====================================
 *           INITIAL STATE
 * ====================================
 */

const initialState: NavigationState = {
  currentTabName: 'Reporting',
};

/**
 * ====================================
 *               SLICE
 * ====================================
 */

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    switchCurrentTabName: (state, action: PayloadAction<TabName>) => {
      state.currentTabName = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

/**
 * ====================================
 *              EXPORTS
 * ====================================
 */

export default navigationSlice.reducer;
export const { switchCurrentTabName } = navigationSlice.actions;
