import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BidRequest, BidResponse } from '../../../declarations';
import { getClaim } from '../../utils/auth/getClaim';
import { getIdToken } from '../../utils/auth/getIdToken';
interface HasuraClaims {
  'X-Hasura-Allowed-Roles': string[];
  'X-Hasura-Default-Role': string;
  'X-Hasura-Role': string;
  'X-Hasura-User-Id': string;
}

const hasuraClaims: HasuraClaims = JSON.parse(getClaim('https://hasura.io/jwt/claims') || '{}')
const idToken = getIdToken();

export const reducerPath = 'eRelayBidBaseApi';

export const api = createApi({
  reducerPath,
  tagTypes: ['Supply'],
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://e.relay.bid/',
    prepareHeaders: (headers, { getState }) => {
      headers.append('authorization', `Bearer ${idToken}`);
      headers.append('role', hasuraClaims['X-Hasura-Role']);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getConfig: builder.query<any, { a: string; supply_id: string }>({
      // TODO
      query: ({ a, supply_id }) => {
        return {
          url: '/m/v1/config/',
          method: 'GET',
          params: { a, supply_id },
        };
      },
      transformResponse: (res: any) => res.data.SupplyConfig,
    }),
    createConfig: builder.mutation<
      any, // TODO
      { a: string; body: any } // TODO
    >({
      query: ({ a, body }) => ({
        url: '/m/v1/config/',
        method: 'POST',
        params: { a },
        body,
      }),
    }),
    updateConfig: builder.mutation<
      any, // TODO
      { a: string; supply_id: string; body: any } // TODO
    >({
      query: ({ a, supply_id, body }) => ({
        url: '/m/v1/config/',
        method: 'PUT',
        params: { a, supply_id },
        body,
      }),
    }),
    postBidRequest: builder.mutation<
      BidResponse,
      { a: string; supply_id: string; request: BidRequest }
    >({
      query: ({ a, supply_id, request }) => ({
        url: `/p/openrtb2`,
        method: 'POST',
        params: { a, supply_id },
        body: request,
      }),
    }),
  }),
});
