import { Routes, Route } from 'react-router-dom';
import { AppRouter } from './AppRouter';
import { Login } from './features/auth/Login';
import ProtectedRoute from './features/auth/ProtectedRoute';
import { ErrorPage } from './ErrorPage';
import { RequireAuth } from './features/auth/RequireAuth';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route
          path="relay/*"
          element={
            <RequireAuth>
              <AppRouter />
            </RequireAuth>
          }
        />
      </Route>
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
export default App;
