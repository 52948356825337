/**
 * ************************************
 *
 * @module ProtectedRoute.tsx
 * @author Adam Joesten
 * @date 6/30/22
 * @description A React/React-Router parent component/route used for authentication/authorization
 *
 * ************************************
 */

import React, { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface ProtectedRouteProps {
  isAllowed?: boolean; // TODO
  redirectPath?: string;
  children?: ReactNode;
}

const ProtectedRoute = ({
  isAllowed = true, // TODO
  redirectPath = '/',
  children,
}: ProtectedRouteProps) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace={true} />;
  }

  return <>{children ? children : <Outlet />}</>;
};

export default ProtectedRoute;
