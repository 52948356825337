/**
 * ************************************
 *
 * @module Navigation.tsx
 * @author Adam Joesten
 * @date 6/17/22
 * @description The place where you navigate around relay-ui
 *
 * ************************************
 */

import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import classNames from '../../utils/tailwind/classNames';
import { switchCurrentTabName } from './navigationSlice';
import { ReactComponent as CodeIcon } from '../../assets/code-icon.svg';
import { ReactComponent as DocumentationIcon } from '../../assets/file-text.svg';
import { ReactComponent as SupplyGroupsIcon } from '../../assets/supply-icon.svg';
import { ReactComponent as DollarSignIcon } from '../../assets/dollar-sign.svg';
import { ReactComponent as Placements } from '../../assets/Placements.svg';
import NavigationTab from './NavigationTab';
import { ReactComponent as RelayLogoHorizontal } from '../../assets/RelayLogoHorizontal.svg';
import { getClaim } from '../../utils/auth/getClaim';
import { NavigationError } from '../../Error';
import { getCompanyName } from './utils/getCompanyName';
import { Tabs } from './NavigationTabList';

const Navigation = () => {
  const currentTabName = useAppSelector(
    (state) => state.navigationReducer.currentTabName,
  );
  const dispatch = useAppDispatch();
  const name = getClaim('name');
  const email = getClaim('email');
  const firstInitial = name.charAt(0).toUpperCase();
  const lastInitial = name.charAt(name.indexOf(' ') + 1).toUpperCase();

  return (
    <div className="flex min-h-0 flex-1 flex-col border-r border-relay-grey-300 bg-relay-grey-200">
      <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4 ">
        <div className="flex flex-shrink-0 items-center justify-center p-8">
          <RelayLogoHorizontal />
        </div>
        <nav
          className="flex-1 space-y-1 bg-relay-grey-200 pl-2"
          aria-label="Sidebar"
        >
          {Tabs.map((tab, idx) => (
            <NavigationTab
              name={tab.name}
              href={tab.href}
              icon={tab.icon(currentTabName)}
              key={`tab-${idx}`}
            />
          ))}
        </nav>
      </div>
      <div className="group border-b pl-2 pb-2 active:border-none">
        <NavigationTab
          name="Documentation"
          href="https://dev.kevel.com/reference/relay-overview"
          count={0}
          external={true}
          icon={<DocumentationIcon />}
        />
      </div>
      <div
        className={classNames(
          currentTabName === 'Accounts'
            ? 'border-r-4 border-relay-blue-500'
            : 'hover:bg-relay-grey-200',
          'bg-relay-gray-100 group flex flex-shrink-0 py-4 pr-4 pl-5 active:border-none ',
        )}
      >
        <Link
          to={'/relay/accounts'}
          onClick={() => dispatch(switchCurrentTabName('Accounts'))}
          className="group block w-full flex-shrink-0 "
        >
          <div className="group flex items-center">
            <div className="flex">
              <div className="flex h-14 w-14 select-none items-center justify-center rounded-full bg-relay-blue-500 text-xl text-white">
                {firstInitial}
                {lastInitial}
              </div>
            </div>
            <div className="ml-3">
              <p
                className={classNames(
                  currentTabName === 'Accounts' ? 'text-relay-blue-500' : '',
                  'text-xl',
                )}
              >
                {name}
              </p>
              <p
                className={classNames(
                  currentTabName === 'Accounts' ? 'text-relay-blue-500' : '',
                  'text-lg',
                )}
              >
                {getCompanyName({ email }) || 'Unknown Company'}
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Navigation;
