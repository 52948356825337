import {
  CognitoIdentityProviderClient,
  CognitoIdentityProviderClientConfig,
  UpdateUserAttributesCommand,
  UpdateUserAttributesCommandInput,
} from '@aws-sdk/client-cognito-identity-provider';
import { Listbox, Transition } from '@headlessui/react';
import { nanoid } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';
import { Fragment, useEffect, useRef, useState } from 'react';
import classNames from '../../utils/tailwind/classNames';
import AddUser from './AddUser';
import {
  useGetAllAccountUsersByEmailQuery,
  GetAllAccountUsersByEmailQuery,
} from './api/GetAllAccountUsersByEmail.generated';

interface UserTeamProps {
  data: { email: any; role: any };
}

const UserTeam = ({ data: { email, role } }: UserTeamProps) => {
  const { data: useGetAllAccountUsersByEmailQueryData } =
    useGetAllAccountUsersByEmailQuery({ _eq: email });
  const users:
    | GetAllAccountUsersByEmailQuery['account'][0]['users']
    | undefined = useGetAllAccountUsersByEmailQueryData?.account[0].users;
  const roles = useGetAllAccountUsersByEmailQueryData?.user_role.map(
    (role) => ({ id: nanoid(), name: role.value }),
  );
  const [selected, setSelected] = useState<null | { id: string; name: string }>(
    null,
  );
  useEffect(() => {
    if (selected === null && roles !== undefined) {
      setSelected(roles[0]);
    }
  }, [selected, roles]);

  const config: CognitoIdentityProviderClientConfig = {
    region: 'US-EAST-1',
  };
  const client = new CognitoIdentityProviderClient(config);
  let newRoleInput: UpdateUserAttributesCommandInput;
  let newRoleCommand: UpdateUserAttributesCommand;
  const [session, setSession] = useState<any>();
  const originalRole = useRef(role);
  useEffect(() => {
    const fetchData = async () => {
      const res = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      setSession(res);
    };
    fetchData();
  }, []);
  if (session && selected) {
    newRoleInput = {
      UserAttributes: [
        {
          Name: 'custom:role',
          Value: selected.name,
        },
      ],
      AccessToken: session.signInUserSession.accessToken.jwtToken || '',
    };
    newRoleCommand = new UpdateUserAttributesCommand(newRoleInput);
  }
  const [newUser, setNewUser] = useState(false);
  return (
    <div className="w-5/12 min-w-fit">
      <div className="mb-2 flex flex-col">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="pb-3.5 pr-3 text-left text-xl font-normal text-black"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="pb-3.5 pr-3 text-left text-xl font-normal text-black"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="w-0 whitespace-nowrap pb-3.5 text-left text-xl font-normal text-black"
                >
                  Access Level
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {users &&
                selected &&
                users.map((user) => (
                  <tr key={nanoid()}>
                    <td className="w-24 whitespace-nowrap py-4 pr-3 text-lg">
                      {user.name}
                    </td>
                    <td className="w-24 whitespace-nowrap py-4 pr-3 text-lg">
                      {user.email}
                    </td>
                    <td className="relative w-24 whitespace-nowrap py-4">
                      <Listbox
                        value={selected}
                        onChange={(e) => {
                          setSelected(e);
                          if (selected.name !== originalRole.current) {
                            client.send(newRoleCommand);
                          }
                        }}
                      >
                        {({ open }) => (
                          <>
                            <Listbox.Label className="sr-only block">
                              Permissions menu
                            </Listbox.Label>
                            <div className="relative">
                              <Listbox.Button className="relative flex w-40 cursor-default rounded-lg pr-10 text-left  focus:outline-none ">
                                <span className="block truncate">
                                  {selected ? selected.name : null}
                                </span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={3}
                                    stroke="currentColor"
                                    className="h-6 w-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                    />
                                  </svg>
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-40 overflow-auto rounded-md bg-white py-1 text-xs ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  {roles &&
                                    roles.map((role) => (
                                      <Listbox.Option
                                        key={role.id}
                                        className={({ active }) =>
                                          classNames(
                                            active
                                              ? 'bg-relay-blue-500 text-white'
                                              : 'text-black',
                                            'relative cursor-default select-none py-2 pl-3 pr-9',
                                          )
                                        }
                                        value={role}
                                      >
                                        {({ selected, active }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                selected
                                                  ? 'font-semibold'
                                                  : 'font-normal',
                                                'block truncate',
                                              )}
                                            >
                                              {role.name}
                                            </span>
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="flex items-center border-t pt-4">
            <button
              className="mr-3 rounded-md bg-relay-grey-200 px-5 py-1"
              onClick={() => setNewUser(!newUser)}
            >
              {!newUser ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 12h-15"
                  />
                </svg>
              )}
            </button>
            <span className="text-lg">Add User</span>
          </div>
        </div>
      </div>
      {newUser && <AddUser roles={roles} setNewUser={setNewUser} />}
    </div>
  );
};

export default UserTeam;
